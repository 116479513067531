<script setup>

const route = useRoute();
const router = useRouter();
const { t } = useI18n()


const handleSort = async ({ sort_by, sort_direction }) => {
  if (!sort_by) {
    const newQuery = { ...route.query };
    delete newQuery[`sort_by`];
    delete newQuery[`sort_direction`];
    return await router.push({
      query: newQuery,
    })
  }

  await router.push({
    query: { ...route.query, sort_by: sort_by, sort_direction: sort_direction },
  })
}
const sort=[
      {
        label: t('client.default'),
        sort: {}
      },
      {
        label: t('client.latest_property'),
        sort: { sort_by: 'id', sort_direction: 'desc' }
      },
      {
        label: t('client.highest_price'),
        sort: { sort_by: 'sale_price', sort_direction: 'desc' }
      },
      {
        label: t('client.lowest_price'),
        sort: { sort_by: 'sale_price', sort_direction: 'asc' }
      },
    ]
</script>
<template>
  <w-drop-down-with-click :marginTop="6" >

    <template #default>
        <button
        class="flex items-center border border-gray-200 text-gray-500 p-1 px-2 rounded hover:bg-gray-100 cursor-pointer">
            <i class="fa-solid fa-sort"></i>
            <span class="text-xs text-gray-600 ps-2">Sort</span>
      </button>
    </template>
    <template  #item >
     
      <div class="w-full">
        <template v-for="item in sort">
          <button @click="handleSort(item.sort)" class="w-full text-left pl-2 py-2 rounded-lg hover:bg-neutral-100">{{ item.label }}</button>
        </template>
      </div>

   
      
    </template>
  </w-drop-down-with-click>
</template>